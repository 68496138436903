import React from 'react';
import { Server } from '@speedy-js/universal/components';
import type { ServerSideRenderContext } from '@speedy-js/universal/interface';
// @ts-ignore
import Entry from '/Users/liyiming/projects/speedystack-new/examples/ssr-react-example/src/pages/blog/index.tsx';

export const Component: React.FC<ServerSideRenderContext> = (props) => {
  return (
    <Server {...props}>
      <Entry />
    </Server>
  );
};

export { getStaticProps } from '/Users/liyiming/projects/speedystack-new/examples/ssr-react-example/src/pages/blog/prefetch.ts'