import {
  defineGetServerData
} from "./chunk.FD6A6SJ5.js";
import {
  Server
} from "./chunk.WLUKVG5Y.js";

// universal_blog-ssg.tsx?intercept
import React2 from "react";

// src/pages/blog/index.tsx
import React from "react";
var App = ({ repos = [] }) => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    onClick: () => {
      console.log(1);
    }
  }, "blog (example of getStaticProps)"), repos.map((repo) => /* @__PURE__ */ React.createElement("div", {
    className: "block",
    key: repo.full_name
  }, /* @__PURE__ */ React.createElement("span", null, "html_url:"), /* @__PURE__ */ React.createElement("span", null, repo.html_url), /* @__PURE__ */ React.createElement("span", null, "description:"), /* @__PURE__ */ React.createElement("span", null, repo.description), /* @__PURE__ */ React.createElement("hr", null))));
};
var blog_default = App;

// src/pages/blog/prefetch.ts
var getStaticPropsTest1InferPropType = defineGetServerData(async () => {
  const repos = await fetch("https://vercel-api-wheat.vercel.app/repos.json").then((data) => data.json());
  return {
    repos,
    time: new Date()
  };
});
var getStaticProps = getStaticPropsTest1InferPropType;

// universal_blog-ssg.tsx?intercept
var Component = (props) => {
  return /* @__PURE__ */ React2.createElement(Server, {
    ...props
  }, /* @__PURE__ */ React2.createElement(blog_default, null));
};
export {
  Component,
  getStaticProps
};
